
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'

@Component
export default class PanelButtons extends Vue {
  @State(({ appState }) => appState.currentTab) currentTab!: number
  @Action setTab!: Function
  @Action setIsEditCard!: Function
  @Action setUserLinks!: Function

  protected toNewCard(): void {
    this.setTab(0)
    this.setIsEditCard(false)
    this.setUserLinks([])
    this.$router.push('/new-card')
  }
}
