
import { Component, Vue } from 'vue-property-decorator'
import Header from '@/components/shared/Header.vue'
import { State, Action } from 'vuex-class'

@Component({
  components: {
    Header,
  },
})
export default class PanelHeader extends Vue {
  @State(({ userState }) => userState.userInfo.fullname) fullname!: string
  @Action setUserName!: Function
  @Action setUserPoints!: Function
}
