
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from 'vue-property-decorator'

import PanelButtons from '@/components/Panel/PanelButtons.vue'
import PanelHeader from '@/components/Panel/PanelHeader.vue'
import { State, Action } from 'vuex-class'

@Component({
  components: {
    PanelButtons,
    PanelHeader,
  },
})
export default class Panel extends Vue {
  @State(({ userState }) => userState.userInfo.fullname) fullname!: string
  @Action setUserName!: Function

  public logout(): void {
    this.$toast.success(`Hasta luego ${this.fullname}`)
    localStorage.clear()
    localStorage.removeItem('jwt')
    this.$router.push('/login')
  }

  mounted() {
    this.setUserName(localStorage.getItem('name'))
  }
}
